@import '../../../libs/shared/src/index';
@import '../../../libs/controls/src/index';
@import './feature/index';
/* IMPORT */

@mixin app-theme($theme) {

  @include shared-theme($theme);
  @include feature-theme($theme);
  @include eurogard-controls-theme($theme);
  /* THEME_INCLUDE */

}

@mixin app-typography($config) {

  @include shared-typography($config);
  @include feature-typography($config);
  @include eurogard-controls-typography($config);
  /* TYPOGRAPHY_INCLUDE */

}
