@use '@angular/material' as mat;

@mixin theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $isDark: map-get($theme, is-dark);

  .eurogard-time-control {

    .eurogard-time-control-element {
      color: mat.get-color-from-palette(map-get($theme, foreground), text);

      &:disabled {
        color: mat.get-color-from-palette(map-get($theme, foreground), disabled-text);
      }

    }

  }
}

@mixin typography($config) {
  .eurogard-time-control {
  }
}
