@use './lib/time-control/time-control.component.theme' as time-control;
@import "./lib/cycle-time-picker/cycle-time-picker.component.theme";
/* IMPORT */

@mixin eurogard-controls-theme($theme) {
  @include time-control.theme($theme);
  @include eurogard-cycle-time-picker-theme($theme);
  /* THEME_INCLUDE */

}

@mixin eurogard-controls-typography($config) {
  @include time-control.typography($config);
  @include eurogard-cycle-time-picker-typography($config);
  /* TYPOGRAPHY_INCLUDE */

}
