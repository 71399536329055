// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '../../../../node_modules/@angular/material-experimental/popover-edit/popover-edit-theme' as popover;
// Plus imports for other components in your app.

@import 'variables';

@import "../index";
/* IMPORT */

@import "../../../../node_modules/@rxap/components/theming";
@import "../../../../node_modules/@rxap/layout/theming";
@import "../../../../node_modules/@rxap/authentication/theming";
@import "../../../../node_modules/@rxap/directives/theming";
@import "../../../../node_modules/@rxap/form-system/theming";
@import "../../../../node_modules/@rxap/data-grid/theming";
@import "../../../../node_modules/@rxap/window-system/theming";

@import 'typography';

@import 'loading-animation';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($typography);

$accent-theme-color: mat.$yellow-palette !default;
$primary-theme-color: mat.$indigo-palette !default;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pwa-primary: mat.define-palette($primary-theme-color);
$pwa-accent: mat.define-palette($accent-theme-color);

// The warn palette is optional (defaults to red).
$pwa-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$pwa-theme-light: mat.define-light-theme($pwa-primary, $pwa-accent, $pwa-warn);
$pwa-theme-dark: mat.define-dark-theme($pwa-primary, $pwa-accent, $pwa-warn);

@mixin themes($theme) {
  @include mat.all-component-themes($theme);
  @include popover.theme($theme);
}

@mixin colors($theme) {
  @include mat.all-component-colors($theme);
  @include popover.color($theme);
}

@function modify-background($theme, $background-color) {
  // Replace the background in the color object's background palette.
  $theme-color: map-get($theme, color);
  $color-background-palette: map-get($theme-color, background);
  $color-background-palette: map-merge($color-background-palette, (background: $background-color));
  $color-background-palette: map-merge($color-background-palette, (raised-button: $background-color));

  // Replace the background in the background palette.
  $background-palette: map-get($theme, background);
  $background-palette: map-merge($background-palette, (background: $background-color));
  $background-palette: map-merge($background-palette, (raised-button: $background-color));

  // Merge the changes into a new theme.
  $modified-theme: map-merge($theme, (color: (background: $color-background-palette)));
  $modified-theme: map-merge($theme, (background: $background-palette));

  @return $modified-theme;
}

@mixin body-theme($theme) {

  $background: mat.get-color-from-palette(map-get($theme, background), background);

  background-color: $background;
  color: mat.get-color-from-palette(map-get($theme, foreground), text);

  .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn, .mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
    background-color: $background;
  }

  @include loadingAnimation($background);

  @include app-theme($theme);
  @include rxap-components-theme($theme);
  @include rxap-layout-theme($theme);
  @include rxap-authentication-theme($theme);
  @include rxap-directives-theme($theme);
  @include rxap-window-system-theme($theme);
  @include rxap-data-grid-theme($theme);
}

@include themes($pwa-theme-light);

body {
  @include body-theme($pwa-theme-light);

  &.dark-theme {
    @include colors($pwa-theme-dark);
    @include body-theme($pwa-theme-dark);
  }
}

@include rxap-form-system-typography($typography);
@include rxap-window-system-typography($typography);
@include rxap-data-grid-typography($typography);
@include rxap-layout-typography($typography);
@include rxap-components-typography($typography);
@include rxap-directives-typography($typography);
@include popover.typography($typography);
@include app-typography($typography);
