@import './machine-details/machine-details.component.theme';
@import './machine-details/steuerwert-table/connection-data-type-cell/connection-data-type-cell.component.theme';
@import './machine-details/steuerwert-table/data-definition-key-item-last-value-cell/data-definition-key-item-last-value-cell.component.theme';
@import "./router-details/router-details.component.theme";
@import "./company-details/hauptkontakt/hauptkontakt.component.theme";
/* IMPORT */

@mixin feature-overview-theme($theme) {

  @include mfd-machine-details-theme($theme);
  @include rxap-connection-data-type-cell-theme($theme);
  @include rxap-data-definition-key-item-last-value-cell-theme($theme);
  @include portal-router-details-theme($theme);
  @include portal-hauptkontakt-theme($theme);
  /* THEME_INCLUDE */

}

@mixin feature-overview-typography($config) {

  @include mfd-machine-details-typography($config);
  @include rxap-connection-data-type-cell-typography($config);
  @include rxap-data-definition-key-item-last-value-cell-typography($config);
  @include portal-router-details-typography($config);
  @include portal-hauptkontakt-typography($config);
  /* TYPOGRAPHY_INCLUDE */

}
