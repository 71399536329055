/* IMPORT */
@use './lib/feature/report/job-execution-table/job-execution-table.component.theme'
  as job-execution-table;

@mixin shared-theme($theme) {
  @include job-execution-table.theme($theme);
  /* THEME_INCLUDE */
}

@mixin shared-typography($config) {
  @include job-execution-table.typography($config);
  /* TYPOGRAPHY_INCLUDE */
}
