@import "~normalize.css";
@import "./scss/theme";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$padding: 1.6rem;

rxap-sidenav-content .container .inner {
  padding: $padding !important;
  height: calc(100% - #{$padding * 2}) !important;
}

.mat-menu-panel {
  max-width: initial !important;
}

.data-grid {
}

.truthy {
  color: rgb(115, 190, 105) !important;
}

.falsy {
  color: rgb(204, 73, 51) !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0 1.6rem !important;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
}

tr.mat-row {
  //height: 4.8rem !important;
}

tr.mat-header-row, tr.mat-row {
  height: 42px !important;
}

.mat-menu-item {
  line-height: 4.8rem;
  height: 4.8rem;
}

.mat-expansion-panel-header {
  height: 48px;
}

.mfd-data-grid {

  th {
    text-align: left;
  }

}

.dark-theme {

  .rxap-navigate-back-button {

    button {
      color: #ffc107 !important;
    }

  }

}
