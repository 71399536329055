@use '@angular/material' as mat;

@mixin portal-router-details-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $isDark: map-get($theme, is-dark);

  .portal-router-details {

    .truthy {
      color: mat.get-color-from-palette($primary);
    }

    .falsy {
      color: mat.get-color-from-palette($warn);
    }

  }

}

@mixin portal-router-details-typography($config) {
  .portal-router-details {
  }
}
