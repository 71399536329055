@import "./overview/index";
/* IMPORT */

@mixin feature-theme($theme) {

  @include feature-overview-theme($theme);
  /* THEME_INCLUDE */

}

@mixin feature-typography($config) {

  @include feature-overview-typography($config);
  /* TYPOGRAPHY_INCLUDE */

}
